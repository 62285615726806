import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { ContentPlusDecor } from "../../../components/widgets/content-plus-decor";
import { FaPlug } from "react-icons/fa";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Sub Processors`}</h1>
    <Breadcrumbs to="/company/" text="Company" mdxType="Breadcrumbs" />
    <ContentPlusDecor decor={<FaPlug mdxType="FaPlug" />} mdxType="ContentPlusDecor">
      <p>{`To provide its services, `}<em parentName="p">{`TuringTrader.com`}</em>{` relies on the following sub-processors:`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Party Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Services`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "https://www.google.com/"
              }}>{`Google`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Hosting, Storage, Functions, Cloud Computing, Analytics`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "https://www.outseta.com/"
              }}>{`Outseta`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Authentication, Payments, CRM, Help Desk, Email`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "https://norgatedata.com/"
              }}>{`Norgate Data`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Market Quotes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "https://fred.stlouisfed.org/"
              }}>{`FRED`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Economic Data`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "https://www.amazon.com/"
              }}>{`Amazon`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Affiliate Links`}</td>
          </tr>
        </tbody>
      </table>
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      